import React, { useEffect, createRef } from 'react'
import PropTypes from 'prop-types'
import {
    Card,
    CardHeader,
    CardContent,
    Typography,
    Button,
    makeStyles
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    container: {
        maxHeight: '80vh',
        overflow: 'scroll',
        marginLeft: '2vw',
        marginRight: '2vw',
        marginBottom: '1vh'
    },
    header: {
        paddingBottom: 0
    },
    content: {
        paddingTop: 0
    },
    name: {
        // display: "block"
    },
    food: {
        color: theme.palette.text.secondary
    }
}))

function RestaurantCard ({ name, food, tags, startTime, endTime, isFocused, setRestaurantFocus, seeLocation, ...props }) {
    const classes = useStyles()

    const ref = createRef()
    useEffect(() => {
        if ( isFocused ) {
            ref.current.scrollIntoView({ behavior: 'smooth'})
        }
    }, [isFocused, ref])

    return(
        <Card
            variant="outlined"
            className={classes.container}
            ref={ref}
        >
            <CardHeader
                title={name}
                subheader={`${ startTime.getDateByHour() }-${ endTime.getDateByHour() }`}
                action={
                    <Button
                        color={'secondary'}
                        variant={'outlined'}
                        onClick={() => {
                            setRestaurantFocus()
                            seeLocation()
                        }}
                    >
                        See On Map
                    </Button>
                }
                className={classes.header}
            />
            <CardContent
                className={classes.content}
            >
                <Typography variant={"body2"} className={classes.food}>{ tags.join(', ') }</Typography>
                <Typography>{ food }</Typography>
            </CardContent>
        </Card>
    )
}

RestaurantCard.propTypes = {
    name: PropTypes.string.isRequired,
    food: PropTypes.string.isRequired,
    tags: PropTypes.array,
    startTime: PropTypes.object,
    endTime: PropTypes.object,
    isFocused: PropTypes.bool,
    setRestaurantFocus: PropTypes.func.isRequired,
    seeLocation: PropTypes.func.isRequired
}

export default RestaurantCard