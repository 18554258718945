import React from 'react'
import PropTypes from 'prop-types'
import {
    BottomNavigation,
    BottomNavigationAction,
    Box,
    makeStyles
} from '@material-ui/core'
import {
    Map,
    Schedule,
    Restaurant
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    container: {
        position: 'fixed',
        bottom: '0',
        width: '100%',
        height: '10vh',
        zIndex: 10000
    }
}))

function createButtonData (text, icon) {
    return { text, icon }
}

const buttonData = [
    createButtonData('Map', <Map />),
    createButtonData('Schedule', <Schedule />),
    createButtonData('Restaurants', <Restaurant />)
]

function CoreComponentSwitch ({ focus, setFocus, ...props }) {
    const classes = useStyles()

    return <Box boxShadow={10} className={classes.container}>
        <BottomNavigation
            showLabels
            value={focus}
            onChange={(e, newValue) => {
                setFocus(newValue)
            }}
            className={classes.container}
        >
                {
                    buttonData.map(({ text, icon }) => (
                        <BottomNavigationAction
                            label={ text }
                            icon={ icon }
                            value={ text }
                            key={ text }
                        />
                    ))
                }
        </BottomNavigation>
    </Box>
}

CoreComponentSwitch.propTypes = {
    focus: PropTypes.string.isRequired,
    setFocus: PropTypes.func.isRequired
}

export default CoreComponentSwitch
