import React from 'react'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { nanoid } from "nanoid";
import InfoIcon from '@material-ui/icons/Info';

const Others = [
    {
        name: "Will Call",
        icon: InfoIcon,
        coordinates: {lat:39.143147, lng: -84.508799}
    },
    {
        name: "Sponsor Suites - Ft Washington",
        icon: MonetizationOnIcon,
        coordinates: {lat:39.14318182723275, lng: -84.50815082249977}
    },
    {
        name: "Sponsor Suites- Honda",
        icon: MonetizationOnIcon,
        coordinates: {lat:39.14321452751097, lng: -84.50821055321396}
    }
].map(other => {
    return {
        ...other,
        id: nanoid()
    }
})

export default Others