import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from "@material-ui/core";
import RestaurantCard from './RestaurantCard';

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: '10vh',
        maxHeight: 'calc(100% - 126px)',
        overflow: 'scroll'
    }
}))

function DisplayRestaurants ({ restaurants, restaurantFocus, setRestaurantFocus, seeLocation, ...props}) {
    const classes = useStyles(props)

    return (
        <div className={classes.container}>
            {
                restaurants.map(({ id, name, food, tags, startTime, endTime }, index) => {
                    const isFocused = restaurantFocus === id
                    return <RestaurantCard
                        key={name + index.toString()}
                        setRestaurantFocus={() => {
                            setRestaurantFocus(id === restaurantFocus ? undefined : id)
                        }}
                        isFocused={isFocused}
                        food={food}
                        name={name}
                        tags={tags}
                        startTime={startTime}
                        endTime={endTime}
                        seeLocation={seeLocation}
                    />
                })
            }
        </div>
    )
}

DisplayRestaurants.propTypes = {
    restaurantFocus: PropTypes.string,
    setRestaurantFocus: PropTypes.func.isRequired,
    seeLocation: PropTypes.func.isRequired,
    restaurants: PropTypes.array.isRequired
}

export default DisplayRestaurants
