class DateUtils {

    constructor(hour, minutes = 0) {
        const date = new Date()
        date.setHours(hour)
        date.setMinutes(minutes)
        this.time = date
    }

    setTime (time) {
        this.time = time
        return this
    }

    getDateByHour () {
        if (typeof this.time === 'string') {
            this.time = new Date(this.time)
        }
        return this.time.toLocaleTimeString().replace(/:\d{2}\s/, ' ')
    }

    static isAvailable (endTime, startTime, bypass = false) {
        const now = new Date();

        // If it isn't event day, return that everything is available
        if (now.getDate() !== 25) {
            return false
        }

        if (!endTime.time || !startTime.time) {
            throw Error('Time must be an instance of DateUtils')
        }

        // const hasStarted = startTime < now
        const hasEnded = new Date(endTime.time) < now

        // This is for only showing things that have started, not using in 2021
        // return (hasStarted && !hasEnded) || bypass

        return hasEnded && !bypass
    }



}

export default DateUtils