import React from 'react'
import PropTypes from 'prop-types'
import { Paper, Typography, Button, makeStyles } from "@material-ui/core"
import { OverlayView, InfoWindow } from "@react-google-maps/api";
import RestaurantIcon from "@material-ui/icons/Restaurant"

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(1),
        maxWidth: 200,
        zIndex: 1500,
        border: "none"
    },
    closeButton: {
        padding: 0,
        flexGrow: 1
    },
    restaurantIcon: {
        zIndex: 900
    },
    restaurantOverlay: {
        zIndex: 1000
    }
}))

function Restaurant ({ coordinates, name, food, startTime, endTime, open = false, setRestaurantFocus, seeMore, ...props }) {
    const classes = useStyles(props)

    function toggleOpen () {
        setRestaurantFocus(open ? undefined : props.id)
    }

    function renderInfoWindow () {
        return open ? <InfoWindow
            position={coordinates}
            onCloseClick={toggleOpen}
        >
            <Paper
                className={classes.paper}
                square
                variant='outlined'
            >
                <Typography variant={'h6'}>
                    { name }
                </Typography>
                <Typography variant={'caption'}>
                    { startTime.getDateByHour()}-{ endTime.getDateByHour() }
                </Typography>
                <Typography variant={'body1'}>
                    { food }
                </Typography>
            </Paper>
        </InfoWindow>
            : ''
    }

    return <React.Fragment>
            <OverlayView mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} position={coordinates}>
                <RestaurantIcon className={classes.restaurantIcon} color={open ? 'secondary' : 'inherit'} onClick={toggleOpen}/>
            </OverlayView>

        {
            renderInfoWindow()
        }

    </React.Fragment>
}

Restaurant.propTypes = {
    coordinates: PropTypes.shape({
        lng: PropTypes.number,
        lat: PropTypes.number
    }).isRequired,
    name: PropTypes.string,
    food: PropTypes.string,
    startTime: PropTypes.object,
    endTime: PropTypes.object,
    open: PropTypes.bool,
    setRestaurantFocus: PropTypes.func.isRequired,
    seeMore: PropTypes.func.isRequired
}

export default Restaurant
