import React from 'react'
import { nanoid } from 'nanoid'
import LocalBar from '@material-ui/icons/LocalBar'

export default [
    {
        name: 'Beer & Wine Bar',
        coordinates: {lat: 39.144875323438875, lng: -84.5097263963715}
    },
    {
        name: 'Beer & Wine Bar',
        coordinates: {lat: 39.146242, lng: -84.507885}
    },
    {
        name: 'Beer & Wine Bar',
        coordinates: {lat: 39.143728238683266, lng: -84.5074967048359}
    },
    {
        name: "Drink Tickets Sales Stations",
        coordinates: {lat: 39.14312868924776, lng: -84.50869191253408}
    },
    {
        name: "Full Bar",
        coordinates: {lat: 39.14341551260239, lng: -84.50871624660816}
    },
    {
        name: "Full Bar +Drink Ticket Sales",
        coordinates: {lat: 39.146351, lng: -84.509630}
    },
    {
        name: "Full Bar +Drink Ticket Sales",
        coordinates: {lat: 39.14451732207221, lng: -84.50717380100203}
    },
    {
        name: "Welcome Drink",
        coordinates: {lat: 39.14299429609001, lng: -84.50908303606055}
    }
]
    .map(bar => {
        return {
            ...bar,
            id: nanoid(),
            icon: LocalBar
        }
    })