import { gql } from '@apollo/client'

export const ADD_LOCATION = gql`
    mutation AddLocation($data: UserLocationInsertInput!) {
        insertOneUserLocation(data: $data) {
            coords
            timestamp
        }
    }
`;

let date = new Date();
export const isCollectionTime = (date.getDate() === 25 && date.getHours() > 18 && date.getHours() < 23)