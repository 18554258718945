import React from 'react'
import {nanoid} from 'nanoid'
import MusicNoteIcon from '@material-ui/icons/MusicNote';

const Music = [
    {
        name: "Wade & Murphy",
        coordinates: {lat: 39.144712186040735, lng: -84.50703152271325}
    },
    {
        name: "DJ Jeremy Hillard",
        coordinates: { lat:39.143138921078354, lng: -84.50918676718344 }
    },
    {
        name: "LoopManDan",
        coordinates: {lat:39.146280, lng: -84.508224}
    },
    {
        name: "Matt Waters Band",
        coordinates: {lat:39.146178, lng: -84.509743}
    },
    {
        name: "Todd of Kentucky Struts",
        coordinates:{lat:39.14343520138473, lng: -84.50696916525585}
    }
].map(game => {
    return {
        ...game,
        id: nanoid(),
        icon: MusicNoteIcon
    }
})


export default Music