import React, { useState, useEffect } from 'react';
import {
    MuiThemeProvider,
    createMuiTheme,
    Container,
    Typography,
    Paper,
    makeStyles
} from '@material-ui/core'
import Axios from 'axios'
import { AppBar, CoreComponentSwitch } from './modules/Navigation'
import { TableSchedule } from './modules/Schedule'
import { MapContainer, MyLocation, Restaurant} from './modules/Map'
import Schedule from './data/Schedule'
import Restaurants from "./data/Restaurants";
import Bars from "./data/Bars";
import Games from './data/Games'
import PhotoBooth from "./data/PhotoBooth";
import Music from './data/Music'
import Others from './data/Others'
import { DisplayRestaurants } from "./modules/Restaurants";
import PointOfInterest from "./modules/Map/components/PointOfInterest";
import AddRestaurant from "./modules/Restaurants/AddRestaurant"

const theme = createMuiTheme({
    palette: {
        primary: {main: "rgb(92, 175, 176)"},
        secondary: {main: "rgb(246, 147, 32)"},
        type: window.location.search.includes('dark') ? 'dark': 'light'
    }
})

const useStyles = makeStyles(theme => ({
    appBar: {
        height: "10vh",
        backgroundColor: theme.palette.background.paper
    },
    appBackground: {
        position: 'absolute',
        top: 0,
        height: '100vh',
        width: '100vw'
    },
    appContainer: {
        margin: '0',
        padding: '0',
        marginTop: '10vh',
        height: '80vh',
        width: '100vw',
        position: "relative"
    },
    clickAway: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        backgroundColor: theme.palette.background.paper
    },
    logo: {
        display: 'block',
        margin: 'auto',
        height: '66px',
        width: '100px'
    },
    menuButton: {
        display: 'none',
        marginRight: theme.spacing(2)
    },
    zooMap: {
        maxWidth: '100vw'
    },
    showMap: {

    },
    hideMap: {
        visibility: 'hidden'
    }
}))

function App() {
    const [ open, setOpen ] = useState(false)
    const [ focus, setFocus ] = useState('Map')
    const [ restaurants, setRestaurants ] = useState([])

    const [ poiFocus, setPoiFocus] = useState(undefined)
    const classes = useStyles()

    useEffect(() => {

        async function fetchData() {
            // const { data } = await Axios.get('https://zoolala.events/api')
            // setRestaurants(data)
        }
        fetchData()
    })

    function focusedComponent () {
        switch ( focus ) {
            default:
                return <div />
            case 'Schedule':
                return <TableSchedule />
            case 'Restaurants':
                return <DisplayRestaurants
                    restaurants={ restaurants }
                    restaurantFocus={poiFocus}
                    setRestaurantFocus={setPoiFocus}
                    seeLocation={() => {
                        setFocus('Map')
                    }}
                />
        }
    }

    return (
        <MuiThemeProvider theme={theme}>
            <Paper className={classes.appBackground} square>

                <AppBar />

                <AddRestaurant />

                {
                    open
                        ? <div onClick={() => {
                            setOpen(false)
                        }} className={classes.clickAway}/>
                        : ''
                }

                <Container className={classes.appContainer} maxWidth={false}>

                    { focusedComponent() }

                    <MapContainer
                        className={ focus === 'Map' ? classes.showMap : classes.hideMap }
                        center={ poiFocus
                            ? [...Others, ...restaurants, ...Schedule, ...Bars, ...PhotoBooth, ...Music, ...Games ]
                                .filter( ({ id }) => id === poiFocus )[0]
                                .coordinates
                            : undefined
                        }
                    >
                        <React.Fragment>
                            <MyLocation />

                            {/* {
                                Schedule.map((event, index) => {
                                    return <PointOfInterest
                                        key={event.event + index.toString()}
                                        CustomIcon={event.icon}
                                        setFocused={setPoiFocus}
                                        open={event.id === poiFocus}
                                        coordinates={event.coordinates}
                                        id={event.id}
                                    >
                                        <Typography>{event.event}</Typography>
                                        <Typography variant="body2" align="center">{event.startTime.getDateByHour()}-{event.endTime.getDateByHour()}</Typography>
                                        { event.description }
                                    </PointOfInterest>
                                })
                            } */}

                            {
                                restaurants.map((food, index) => <Restaurant
                                    key={food.name + index.toString()}
                                    coordinates={food.coordinates}
                                    name={food.name}
                                    food={food.food}
                                    startTime={food.startTime}
                                    endTime={food.endTime}
                                    open={ food.id === poiFocus }
                                    setRestaurantFocus={setPoiFocus}
                                    seeMore={() => {
                                        setFocus('Restaurants')
                                        setPoiFocus(food.id)
                                    }}
                                    {...food}
                                />)
                            }

                            {/* {
                                Bars.map((bar, index) => {
                                    return <PointOfInterest
                                        key={bar.name + index.toString()}
                                        id={bar.id}
                                        open={bar.id === poiFocus}
                                        setFocused={setPoiFocus}
                                        coordinates={bar.coordinates}
                                        CustomIcon={bar.icon}
                                    >
                                        <Typography>{bar.name}</Typography>
                                    </PointOfInterest>
                                })
                            } */}

                            {/* {
                                Games.map((game, index) => {
                                    return <PointOfInterest
                                        key={game.name + index.toString()}
                                        id={game.id}
                                        open={game.id === poiFocus}
                                        setFocused={setPoiFocus}
                                        coordinates={game.coordinates}
                                        CustomIcon={game.icon}
                                    >
                                        <Typography>{game.name}</Typography>
                                    </PointOfInterest>
                                })
                            } */}

                            {/* {
                                PhotoBooth.map((photo, index) => {
                                    return <PointOfInterest
                                        key={photo.name + index.toString()}
                                        id={photo.id}
                                        open={photo.id === poiFocus}
                                        setFocused={setPoiFocus}
                                        coordinates={photo.coordinates}
                                        CustomIcon={photo.icon}
                                    >
                                        <Typography>{photo.name}</Typography>
                                    </PointOfInterest>
                                })
                            } */}

                            {/* {
                                PhotoBooth.map((photo, index) => {
                                    return <PointOfInterest
                                        key={photo.name + index.toString()}
                                        id={photo.id}
                                        open={photo.id === poiFocus}
                                        setFocused={setPoiFocus}
                                        coordinates={photo.coordinates}
                                        CustomIcon={photo.icon}
                                    >
                                        <Typography>{photo.name}</Typography>
                                    </PointOfInterest>
                                })
                            } */}

                            {/* {
                                Music.map((music, index) => {
                                    return <PointOfInterest
                                        key={music.name + index.toString()}
                                        id={music.id}
                                        open={music.id === poiFocus}
                                        setFocused={setPoiFocus}
                                        coordinates={music.coordinates}
                                        CustomIcon={music.icon}
                                    >
                                        <Typography>{music.name}</Typography>
                                    </PointOfInterest>
                                })
                            } */}

                            {/* {
                                Others.map((other, index) => {
                                    return <PointOfInterest
                                        key={other.name + index.toString()}
                                        id={other.id}
                                        open={other.id === poiFocus}
                                        setFocused={setPoiFocus}
                                        coordinates={other.coordinates}
                                        CustomIcon={other.icon}
                                    >
                                        <Typography>{other.name}</Typography>
                                    </PointOfInterest>
                                })
                            } */}

                        </React.Fragment>
                    </MapContainer>

                </Container>

                <CoreComponentSwitch
                    focus={focus}
                    setFocus={navFocus => {
                        setFocus(navFocus)
                        setPoiFocus(undefined)
                    }}
                />
            </Paper>
        </MuiThemeProvider>
    );
}

export default App;
