import React from 'react'
import {
    Typography,
    makeStyles
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    container: {
        margin: '0 8px'
    },
    description: {
        whiteSpace: 'pre-wrap'
    }
}))

function ScheduleItem ({ startTime, endTime, event, location, description, ...props }) {
    const classes = useStyles()
    
    return (
        <div className={classes.container}>
            <Typography variant="h6" align="center">{event}</Typography>
            <Typography variant="body2" align="center">{startTime.getDateByHour()}-{endTime.getDateByHour()}</Typography>
            <Typography variant="subtitle1" align="center">{location}</Typography>
            <div className={classes.description}>{description}</div>
        </div>
    )
}

export default ScheduleItem