import React from 'react'
import LocalPlayIcon from '@material-ui/icons/LocalPlay';
import { nanoid } from 'nanoid'

const Games = [{
    name: "Who Killed My Goat",
    coordinates: {lat:39.14522546668582, lng: -84.50684456144451}
},
{
    name: "Day in the Life MadLibs",
    coordinates: {lat:39.146228, lng: -84.508111}
},
{
    name: "Tortoise Bingo",
    coordinates: {lat:39.1445736683354, lng: -84.50745230318431}
},
{
    name: "Animal Jeopardy",
    coordinates: {lat:39.14337551260239, lng: -84.50871624660816}
},
{
    name: "Feed the Animals Cornhole",
    coordinates: {lat:39.144889688327375, lng: -84.50986531244506}
}].map(game => {
    return {
        ...game,
        id: nanoid(),
        icon: LocalPlayIcon
    }
})

export default Games