import React from 'react'
import PropTypes from 'prop-types'
import {
    CircularProgress,
    Typography,
    makeStyles
} from "@material-ui/core";
import { useLoadScript, GoogleMap} from "@react-google-maps/api";
import MapsStyles from './MapsStyles'
import DarkMapsStyles from './DarkMapsStyles'

const useStyles = makeStyles(theme => ({
    mapContainer: {
        height: 'calc(100% - 126px)',
        width: '100%',
        position: 'absolute',
        top: 0,
        zIndex: theme.zIndex.appBar - 2,
        backgroundColor: theme.palette.background.paper
    },
    loadError: {
        position: 'relative',
        top: '32vh'
    }
}))

function MapContainer ({ center, className, children }) {
    const classes = useStyles()
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY
    })

    function renderMap () {
        if ( isLoaded ) {
            return <div className={className}>
                <GoogleMap
                    mapContainerClassName={classes.mapContainer}
                    zoom={17}
                    center={center
                        ? center
                        : {
                            lng: -84.508,
                            lat: 39.145
                        }
                    }
                    options={{
                        styles: window.location.search.includes('?dark') ? DarkMapsStyles : MapsStyles,
                        disableDefaultUI: true,
                        restriction: {
                            latLngBounds: {
                                north: 39.148,
                                south: 39.1422,
                                west: -84.513,
                                east: -84.505
                            },
                            strictBounds: true
                        }
                    }}
                >
                    {
                        children
                    }
                </GoogleMap>
            </div>
        } else if ( loadError ) {
            return <div className={className}>
                <div className={classes.mapContainer}>
                    <Typography align="center" className={classes.loadError}>
                        There has been an error loading the map
                    </Typography>
                </div>
            </div>
        } else {
            return <div className={classes.mapContainer}><CircularProgress /></div>
        }
    }

    return renderMap()
}

MapContainer.propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
    center: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number
    })
}

export default MapContainer
