import React from 'react'
import DateUtils from "./dateUtils";
import { nanoid } from 'nanoid'
import { Typography, List, ListItem, ListItemText } from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'

const schedule = [
  {
    "startTime": new DateUtils(18, 30),
    "endTime": new DateUtils(19, 30),
    "event": "VIP Reception",
    "location": "Vine Street Village",
    "description": <React.Fragment>
      <Typography variant={'subtitle2'} align='center'>VIP ticket holders only</Typography>
  <Typography>Exclusive Food Sampling by Thunderdome Restaurant Group</Typography>
  <Typography>Private animal experiences including:</Typography>
  <List dense>
    <ListItem>
      <ListItemText primary="Elephant Bath Viewing" />
    </ListItem>
    <ListItem>
      <ListItemText primary="Galapagos Tortoise Open House" />
    </ListItem>
    <ListItem>
      <ListItemText primary="Roo Valley Walk Through" />
    </ListItem>
  </List>
</React.Fragment>,
    "coordinates": {
      lng: -84.5092,
      lat: 39.143
    }
  },
  {
    "startTime": new DateUtils(19, 30),
    "endTime": new DateUtils(21, 30),
    "event": "Dinner-by-the-bite",
    "location": "",
    "description": <Typography variant="body1">Taste food samples from local restaurants while you wander around the Zoo. Experience the Zoo as not many get to do, with drinks in hand and the entire Zoo to explore.</Typography>,
    "coordinates": {
      lng: -84.508,
      lat: 39.145
    }
  },
  {
    startTime: new DateUtils(19, 30),
    endTime: new DateUtils(21, 30),
    event: 'Bourbon Tasting',
    location: "Giraffe Ridge",
    description: <React.Fragment>
      <Typography variant={'subtitle2'} align='center'>$20 add on</Typography>
      <Typography>
        NEW this year - we've partnered with Brain Brew Custom Whiskey to bring a new bourbon tasting experience to Zoo La La. You will get to taste four custom whiskeys while also supporting Zoo Education programs.
      </Typography>
    </React.Fragment>,
    coordinates: {
      lat:39.14350466823326,
      lng: -84.50697812215859
    }
  },
  {
    "startTime": new DateUtils(20, 0),
    "endTime": new DateUtils(20, 20),
    "event": "Special Wings of Wonder Encounter",
    "location": "Wings of Wonder Theater",
    "description": <Typography variant="body1">Experience a one-of-a-kind bird encounter where birds will fly overhead!</Typography>,
    "coordinates": {
      lng: -84.50940582943393,
      lat: 39.14571873135727
    }
  }
]
  .map(itm => {
    return {
      ...itm,
      id: nanoid(),
      icon: EventIcon,
      isAvailable: DateUtils.isAvailable(itm.endTime, itm.startTime, process.env.NODE_ENV === 'development')
    }
  })
    .filter(itm => !itm.isAvailable)

export default schedule