import React from 'react'
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import {nanoid} from "nanoid";

const PhotoBooth = [
    {
        name: "Framester Photo Booth",
        coordinates: {lat:39.146390, lng: -84.509481}
    }
].map(photo => {
    return {
        ...photo,
        id: nanoid(),
        icon: CameraAltIcon
    }
})

export default PhotoBooth