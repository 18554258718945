import React from 'react'
import {
    Paper,
    Divider,
    makeStyles
} from "@material-ui/core";
import ScheduleItem from './ScheduleItem'
import data from '../../data/Schedule'
import Sponsor from './sponsor.jpg'

const useStyles = makeStyles(theme => ({
    stickyTableHeader: {
        // position: 'fixed',
        // top: '60px'
    },
    tableBody: {

    },
    sponsorContainer: {
        height: '80px'
    },
    sponsor:  {
        margin: '1vh auto',
        display: 'block',
        maxWidth: '275px'
    },
    container: {
        position: 'absolute',
        zIndex: theme.zIndex.appBar - 1,
        width: '100%',
        maxHeight: 'calc(100% - 126px)',
        overflow: 'scroll'
    }
}))

function TableSchedule () {
    const classes = useStyles()

    return (
        <Paper elevation={0} className={classes.container} square>
            <div className={classes.sponsorContainer}>
                <img className={classes.sponsor} src={Sponsor} alt="Fort Washington Investment Advisors, Inc." />
            </div>
            {
                data.map(({startTime, endTime, event, location, description}, index) => (
                    <React.Fragment key={event + index.toString()}>
                        <Divider/>
                        <ScheduleItem startTime={startTime} endTime={endTime} event={event} description={description} location={location} />
                    </React.Fragment>
                ))
            }
        </Paper>
    )
}

export default TableSchedule