import React from 'react'
import PropTypes from 'prop-types'
import { Paper, makeStyles } from "@material-ui/core"
import { OverlayView, InfoWindow } from "@react-google-maps/api";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(1),
        maxWidth: 200,
        zIndex: 1500,
        border: "none"
    },
    closeButton: {
        padding: 0,
        flexGrow: 1
    },
    customIcon: {
        zIndex: 900
    },
    customOverlay: {
        zIndex: 1000
    }
}))

function PointOfInterest ({ CustomIcon, coordinates, open = false, setFocused, children, ...props }) {
    const classes = useStyles(props)

    function toggleOpen () {
        setFocused(open ? undefined : props.id)
    }

    function renderInfoWindow () {
        return open ? <InfoWindow
                position={coordinates}
                onCloseClick={toggleOpen}
            >
                <Paper
                    className={classes.paper}
                    square
                    variant='outlined'
                >
                    {
                        children
                    }
                </Paper>
            </InfoWindow>
            : ''
    }

    return <React.Fragment>
        <OverlayView mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} position={coordinates}>
            <CustomIcon className={classes.customIcon} color={open ? 'secondary' : 'inherit'} onClick={() => {
                toggleOpen()
            }}/>
        </OverlayView>

        {
            renderInfoWindow()
        }

    </React.Fragment>
}

PointOfInterest.propTypes = {
    id: PropTypes.string.isRequired,
    coordinates: PropTypes.shape({
        lng: PropTypes.number,
        lat: PropTypes.number
    }).isRequired,
    open: PropTypes.bool,
    setFocused: PropTypes.func.isRequired,
    CustomIcon: PropTypes.object.isRequired
}

export default PointOfInterest
