import React from 'react'
import { Dialog, DialogTitle, TextField, Button } from '@material-ui/core'
import Axios from 'axios'

function AddRestaurant () {
    const isOpen = window.location.search.includes('addpoi')

    async function saveData (e) {
        e.preventDefault()
    }

    return (
        <Dialog open={isOpen}>
            <DialogTitle>Add Restaurant</DialogTitle>
            <form action="/api" method="POST">
                <TextField id="name" label="Name" variant="filled" />
                <TextField id="food" label="Food" variant="filled" />
                <br />
                <Button type="submit">Save</Button>
            </form>
        </Dialog>
    )
}

export default AddRestaurant