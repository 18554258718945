import React from 'react'
import PropTypes from 'prop-types'
import { Marker } from "@react-google-maps/api";
import { useMutation } from '@apollo/client'
import { ADD_LOCATION, isCollectionTime } from '../../data/realm'
import LocationIcon from '../../assets/myLocation.svg'

function MyLocation ( props ) {
    const [ currentLocation, setCurrentLocation ] = React.useState({
        lng: -84.508,
        lat: 39.145
    })

    const [addLocation] = useMutation(ADD_LOCATION)

    React.useEffect(() => {
        const baseInterval = 30000
        let interval = 100
        let tries = 1;
        let timeout = undefined

        function nextPing (backoff = false) {
            if (backoff && tries > 5) {
                return
            } else if (backoff) {
                tries++
                interval = baseInterval * tries
            } else if (!backoff && tries !== 1) {
                tries = 1
                interval = 30000
            }
            ping()
        }

        function ping () {
            timeout = window.setTimeout(() => {
                if (window.location.origin.includes('zoolala.events') && isCollectionTime) {
                    addLocation({
                        variables: {
                            data: {
                                coords: [ currentLocation.lng, currentLocation.lat ],
                                timestamp: new Date().toISOString()
                            }
                        }
                    }).then(() => {
                        interval = 30000
                        nextPing()
                    }).catch(e => {
                        nextPing(true)
                    })
                }
            }, interval)
        }

        ping()

        const watchPositionID = navigator.geolocation.watchPosition(({ coords }) => {
            setCurrentLocation({lng: coords.longitude, lat: coords.latitude})
        }, error => console.log(error))

        return () => {
            navigator.geolocation.clearWatch(watchPositionID)
            if (timeout) {
                window.clearTimeout(timeout)
            }
        }
    }, [addLocation])

    return (
        <React.Fragment>
            {
                currentLocation
                    ? <Marker
                        position={currentLocation}
                        icon={LocationIcon}
                    />
                    : ''
            }
        </React.Fragment>
    )
}

MyLocation.propTypes = {
    currentLocation: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number
    })
}

export default MyLocation