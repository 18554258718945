import DateUtils from "./dateUtils"
import { nanoid } from 'nanoid'

function createRestaurant ({ name, food, tags = [], startTime, endTime, coordinates }) {
    return { name, food, tags,
        id: nanoid(),
        "coordinates": {
            lng: coordinates.lng,
            lat: coordinates.lat
        },
        startTime,
        endTime,
        isAvailable: DateUtils.isAvailable(endTime, startTime, process.env.NODE_ENV === 'development')
    }
}

export default [
    {
        name: 'AnishaArts',
        food: 'Bhel Puri',
        tags: [],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.145506, lng: -84.510080}
    },
    {
        name: 'Bakersfield',
        food: 'Chips & Guacamole',
        tags: [ 'Vegan', 'Gluten Free' ],
        startTime: new DateUtils(18, 30),
        endTime: new DateUtils(19,30),
        coordinates: {lat:39.14318979719742, lng: -84.50841082639693}
    },
    {
        name: 'Bibibop Asian Grill',
        food: 'Sampling of build your bowl',
        tags: [ 'Savory', 'Sweet', 'Vegetarian', 'Gluten Free' ],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.145943, lng: -84.509697}
    },
    {
        name: 'Buckabee Brownies',
        food: 'Brownies and Blondies',
        tags: [ 'Sweet', 'Gluten Free' ],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.146251, lng: -84.508041}
    },
    {
        name: 'Che',
        food: 'Empanadas!',
        tags: [ 'Savory' ],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.145942619577106, lng: -84.50980322233337}
    },
    {
        name: 'Cheesecakes By Ken',
        food: 'Cheesecake bites',
        tags: [ 'Sweet', 'Gluten Free' ],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.1436526721259, lng: -84.50891637853212}
    },
    {
        name: 'Chick Fil A',
        food: 'Cookies',
        tags: [ 'Sweet' ],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.143699806740884, lng: -84.5089254947093}
    },
    {
        name: 'City Barbeque',
        food: 'Sliced brisket',
        tags: [ 'Savory', 'Gluten Free' ],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.145257, lng: -84.510110}
    },
    {
        name: 'Coffee Emporium',
        food: 'Cold Brew, Iced Tea, Coffee, Lemonade',
        tags: [],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.14347356030112, lng: -84.50891333980641}
    },
    {
        name: 'Dinner to Doorbells',
        food: 'Asian Lettuce Wraps (chicken & cauliflower)',
        tags: [ 'Savory', 'Vegetarian' ],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.145494, lng: -84.510040}
    },
    {
        name: 'Duck Donuts',
        food: 'Donuts',
        tags: [],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.14505799310157, lng: -84.50633970088333}
    },
    {
        name: "Eddie Merlot's",
        food: 'Bourbon Beef Brochettes',
        tags: [ 'Savory' ],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.146298, lng: -84.509622}
    },
    {
        name: 'Fausto',
        food: 'Primaterra Farm Duck Liver Mousse',
        tags: [ 'Savory' ],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.14594342676881, lng: -84.50985630373431}
    },
    {
        name: 'Forno',
        food: 'Gnocchi',
        tags: [ 'Savory' ],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.14593454765975, lng: -84.50983652831043}
    },
    {
        name: "Gabby's Cafe",
        food: 'Italian Meatballs in Marinara',
        tags: [ 'Savory' ],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.14513947622799, lng: -84.507297916105}
    },
    {
        name: 'Hearth at Karrikin',
        food: 'Caesar Asparagus',
        tags: [ 'Savory', 'Gluten Free' ],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.146236, lng: -84.507920}
    },
    {
        name: "Holtman's Donuts",
        food: 'Donut Holes',
        tags: [ 'Sweet' ],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.14592728293332, lng: -84.50985630373431}
    },
    {
        name: "LaRosa's",
        food: 'Pizza slices',
        tags: [ 'Savory', 'Vegetarian' ],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.146282, lng: -84.508127}
    },
    {
        name: "Mom 'n 'em Coffee",
        food: 'Coffee Cart',
        tags: [],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.145935354851524, lng: -84.50986046698144}
    },
    {
        name: 'Pata Roja Taqueria',
        food: '',
        tags: [],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.146161, lng: -84.509037}
    },
    {
        name: 'Pepp&Dolores',
        food: 'Tri-colore – Seasoned Ricotta and Roasted Heirloom Tomatoes, Basil, Crostini',
        tags: [ 'Vegetarian' ],
        startTime: new DateUtils(18, 30),
        endTime: new DateUtils(19,30),
        coordinates: {lat:39.14338979710742, lng: -84.50822082639693}
    },
    {
        name: 'Pepp&Dolores',
        food: "Meatball - Veal & Pork Meatball, Nonna's Red Sauce",
        tags: [ 'Savory' ],
        startTime: new DateUtils(18, 30),
        endTime: new DateUtils(19,30),
        coordinates: {lat:39.14318979710742, lng: -84.50821082639693}
    },
    {
        name: 'Queen City Cotton Candy',
        food: 'Gourmet Cotton Candy',
        tags: [ 'Sweet', 'Vegetarian', 'Gluten Free' ],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.145038523285905, lng: -84.50734332406951}
    },
    {
        name: 'Ruya',
        food: 'Coffee Drinks',
        tags: [ 'Sweet', 'Vegetarian', 'Gluten Free' ],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.14500722541762, lng: -84.50636588495884}
    },
    {
        name: 'Skyline',
        food: 'Cheese Coneys',
        tags: [],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.14491644046485, lng: -84.5073524056624}
    },
    {
        name: 'Smoothie Kings',
        food: 'Smoothie samples',
        tags: [ 'Savory', 'Sweet' ],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.143690379820434, lng: -84.50884648784044}
    },
    {
        name: 'Southern Grace',
        food: 'Vegan, Vegetarian Pasta',
        tags: [ 'Vegan', "Vegetarian"],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.14366209905141, lng: -84.50890118490351}
    },
    {
        name: 'Street City Pub',
        food: 'Brussel Sprouts',
        tags: [],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.145096068840466, lng: -84.50630042477006}
    },
    {
        name: 'The Eagle',
        food: 'Hush Puppy - Corn, Jalapeno, Cheddar, Comeback Sauce',
        tags: [ 'Vegetarian' ],
        startTime: new DateUtils(18, 30),
        endTime: new DateUtils(19,30),
        coordinates: {lat:39.14329979710742, lng: -84.50841082639693}
    },
    {
        name: 'Whole Foods',
        food: 'TTLA (tempeh, tomato, lettuce & avocado) wraps ',
        tags: [ 'Savory', 'Gluten Free' ],
        startTime: new DateUtils(19, 30),
        endTime: new DateUtils(21,30),
        coordinates: {lat:39.146278, lng: -84.508092}
    }
]
    .map(createRestaurant)
    .sort((a, b) => {
        if ( a.name < b.name ) {
            return -1
        }
        if ( a.name > b.name ) {
            return 1
        }
        return 0
    })
    .sort((a, b) => {
        const aTime = new Date(a.endTime.time)
        const bTime = new Date(b.endTime.time)
        if (aTime < bTime) {
            return -1
        }
        if (aTime > bTime) {
            return 1
        }
        return 0
    })
    .filter(itm => !itm.isAvailable)