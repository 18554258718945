import React from "react";
import { AppBar, Toolbar, Button, makeStyles } from "@material-ui/core";
import ZooLaLaLogo from "../../assets/zoo-la-la-2021-logo.png";

const useStyles = makeStyles(theme => ({
    appBar: {
        height: "10vh",
        backgroundColor: theme.palette.background.paper
    },
    logo: {
        display: 'block',
        height: '66px',
        width: '100px',
        // margin: 'auto'
    },
    spacer: {
        flexGrow: 1
    },
    menuButton: {
        // marginRight: theme.spacing(0)
    }
}))

function CustomAppBar () {
    const classes = useStyles()

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                <img src={ZooLaLaLogo} alt="ZooLaLa Logo" className={classes.logo} />

                <div className={classes.spacer} />

                <Button
                    onClick={() => {
                        window.location = "http://zoolala.givesmart.com/"
                    }}
                    color="primary"
                >
                    Donate
                </Button>

            </Toolbar>
        </AppBar>
    )
}

export default CustomAppBar
